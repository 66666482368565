import React from 'react'
import parse from 'html-react-parser'
import { graphql } from 'gatsby'
import HubspotForm from 'react-hubspot-form'
import moment from 'moment'

//containers
import Hero from 'containers/Blog/hero'

//  components
import Layout from 'components/layout'
import SEO from 'components/seo'

import {
  Container,
  FormContainer,
  PostDiv,
  ContentPost,
  AuthorDiv
} from './postContent.styles'

export const query = graphql`
  query($id: String!) {
    allPost(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        body
        state
        author {
          id
          name
          avatar
          bio
        }
        image {
          url
          alt_text
        }
        published
      }
    }
    mailIcon: file(relativePath: { eq: "news_newsletter.svg" }) {
      publicURL
    }
  }
`

const Index = ({
  data: {
    allPost: { nodes },
    mailIcon
  }
}) => {
  const icon = mailIcon.publicURL
  const {
    title,
    body,
    author: { name, avatar, bio },
    image: { url, alt_text },
    published
  } = nodes[0]

  return (
    <Layout>
      <SEO title={`News - ${title}`} />
      <Hero colorDiv={true} />
      <Container>
        <PostDiv>
          <h1>{title}</h1>
          <div className="autorInfo">
            <img src={avatar} alt="avatar" id="avatarImg" />
            <span>{name}</span>
            <span>{moment(published).format('LL')}</span>
          </div>
          <img src={url} alt={alt_text} id="mainImage" />
          <ContentPost>{parse(body)}</ContentPost>
          <AuthorDiv>
            <div className="titleBio">
              <h4>About the Author</h4>
            </div>
            <div className="bioDiv">
              <img src={avatar} alt="avatar2" id="bioImg" />
              <div>
                <h1>{name}</h1>
                <span>{bio}</span>
              </div>
            </div>
          </AuthorDiv>
        </PostDiv>
        <div>
          <FormContainer>
            <img src={icon} alt="icon-edge" />
            <h3>Sign up for our newsletter</h3>
            <HubspotForm
              portalId="7940905"
              formId="7c553fd2-fa8e-4aa9-886f-6e2404796059"
              onSubmit={() => console.log('Submit!')}
              onReady={(form) => console.log('Form ready!')}
              loading={<div>Loading...</div>}
            />
          </FormContainer>
        </div>
      </Container>
    </Layout>
  )
}

export default Index
