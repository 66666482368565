import styled from 'styled-components'

export const ContainerHero = styled.div`
  background-color: ${(props) => (props.colorDiv ? '#F8FCFD' : '#FFFFFF')};
  display: flex;
  padding: 0 3rem;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  height: 15rem;
  max-width: 135rem;

  @media only screen and (min-width: 1750px) {
    /* padding: 0 20rem; */
  }

  @media only screen and (min-width: 767px) and (max-width: 1100px) {
    h1 {
      font-size: 3.7rem !important;
    }
  }

  @media only screen and (max-width: 766px) {
    padding: 0 5rem;

    h1 {
      font-size: 3.2rem !important;
    }

    .breadcrumb {
      margin-top: 2rem;
    }
  }

  h1 {
    color: #373f49;
    font-size: 4.5rem;
    font-weight: 800;
  }

  .breadcrumb {
    padding: 10px 16px;
    list-style: none;
    background-color: transparent;

    & li {
      display: flex;
      font-size: 16px;
      padding: 8px;
      color: #147efb;

      & a {
        color: #147efb;
        text-decoration: none;
        margin-right: 1rem;
        min-width: 50px;
        display: block;
      }
    }
  }
`
