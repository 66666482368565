import { Link } from 'gatsby'
import React from 'react'

//styles
import { ContainerHero } from './hero.style'

const index = ({ colorDiv }) => {
  return (
    <ContainerHero colorDiv={colorDiv}>
      <h1>News</h1>
      <ul className="breadcrumb">
        <li>
          <Link to="/">Home</Link> |
        </li>
        <li>
          <Link to="/news">News</Link>
        </li>
      </ul>
    </ContainerHero>
  )
}

export default index
