import styled from 'styled-components';


export const Container = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 10rem 8rem;

  @media only screen  and (min-width: 1500px){
    padding: 10rem 16rem;
  }

  @media only screen  and (max-width: 1100px){
    grid-template-columns: repeat(1, 1fr);
    padding: 4rem 5rem;
  }

  @media only screen and (max-width: 450px){
    padding: 2rem 1.5rem;
  }
`;


export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #147efb;
    border-radius: 20px;
    margin-top: 2rem;
    img {
        width: 55px;
        margin-top: 4rem;
    }

    h3 {
        font-size: 2.7rem;
        color: #FFFFFF;
        margin: 2rem auto;
        width: 50%;
        width: 70%;
        text-align: center;
    }
`;

export const PostDiv = styled.div`
  padding: 0 8rem 0 4rem;

  @media only screen   and (max-width: 1100px){
    padding: 0 2rem;
  }

  h1 {
    font-size: 3.7rem;
    color: #147efb;
    line-height: 56px;
  }

  .autorInfo {
    display: flex;
    align-items: center;
    padding: 2rem 0;

    span {
      color: #788293;
      font-size: 16px;
      margin: 0 .8rem;
      font-weight: 800;
      :last-child{
        font-weight: 400;
      }
    }
  }

  #avatarImg{
    border-radius: 50%;
    width: 4.8rem;
    height: 4.8rem;
  }

  #mainImage {
    width: 100%;
  }
`;

export const ContentPost = styled.div`
  margin-top: 5rem; 

  p {
    font-size: 1.6rem;
  }
`;

export const AuthorDiv = styled.div`
  margin: 2rem 0;

  .titleBio {
    border-top: 1px solid #A9D3EB;
    position: relative;
    margin: 4rem 0;
  }

  h4 {
    position: absolute;
    color: #147efb;
    font-size: 2.2rem;
    font-weight: 400;
    background: white;
    top: -14px;
    padding-right: 3rem;
  }

  #bioImg {
    border-radius: 50%;
    width: 16.8rem;
    height: 16.8rem;
    margin-right: 5rem;
  }

  .bioDiv{
    display: flex;
    @media only screen and (max-width: 800px) {
      display: block;
    }

    span{
      color: #788293;
      font-size:1.8rem;
      line-height: 30px;
      font-weight: 400;
    }
  }
`;